.archive-container {
  color: white; /* テキストの色を白に設定 */
}

.pdf-link {
  color: white;
  text-decoration: none;
}

.return-home {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #778899;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.return-home:hover {
  background-color: #0056b3;
}
