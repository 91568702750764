.App {
  text-align: center;
  background-color: #303030; /* 灰色 */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background-image-link {
  text-decoration: none; /* リンクの下線を消す */
  display: block; /* リンクをブロック要素として扱う */
  height: 100vh; /* ビューポートの高さに合わせる */
}

.background-image {
  background-image: url('../public/another-background1.png');
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain; /* 画像がはみ出さないように調整 */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #131212;
}

/* スマホサイズでのスタイル調整 */
@media (max-width: 768px) {
  .background-image {
    background-size: contain, contain; /* 画面サイズに合わせて調整 */
  }
}


.text-content {
  color: white; /* テキストの色 */
  font-size: 2em; /* フォントサイズ */
  text-align: center;
}

.link-container {
  margin-top: 20px;
  text-align: center;
}

.link-button {
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  background-color: #778899;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.1s;
  width: 180px; /* ボタンの幅を指定 */
  text-align: center; /* ボタン内のテキストを中央揃えにする */
}

.link-button:hover {
  background-color: #0056b3;
}

.archive-container {
  color: white; /* テキストの色を白に設定 */
}

.pdf-link {
  color: white;
  text-decoration: none;
}

.menu-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px; /* PCのデフォルトサイズ */
  height: 25px; /* PCのデフォルトサイズ */
  z-index: 1000;
  position: fixed;
  top: 20px;
  left: 20px;
  border-radius: 5px; /* 角を丸める */
}

.bar {
  height: 3px;
  width: 100%;
  background-color: #fff;
  transition: all 0.3s ease;
  border-radius: 2px; /* バーの角を丸める */
}

.menu-content {
  position: fixed;
  top: 0;
  left: 0; /* 'right' を 'left' に変更 */
  width: 100%; /* スマホでは全画面、PCでは幅を調整 */
  background: rgba(0,0,0,0.9); /* 半透明の背景 */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateX(-100%); /* 初期状態では左に隠れている */
  transition: transform 0.3s ease-in-out;
}

.menu-content.open {
  transform: translateX(0); /* メニューを表示 */
}

.menu-link {
  font-family: 'Dancing Script', cursive; /* Google Fontsから読み込んだフォントを指定 */
  color: white; /* テキストの色を白に */
  text-decoration: none; /* デフォルトでは下線を非表示 */
  padding: 10px;
  font-size: 24px;
  transition: text-decoration 0.3s ease-in-out; /* 下線の表示をスムーズに */
}

.menu-link:hover {
  text-decoration: underline; /* マウスオーバー時に下線を表示 */
}

/* スマホとPCでのスタイル調整 */
@media (min-width: 769px) {
  .menu-icon {
    width: 25px; /* スマホでのサイズを小さくする */
    height: 20px; /* スマホでのサイズを小さくする */
    top: 15px; /* スマホでの位置を調整 */
    left: 15px; /* スマホでの位置を調整 */
  }

  .bar {
    height: 2px; /* スマホでのバーの高さを少し細くする */
  }

  .menu-content {
    width: 300px; /* PCではメニュー幅を固定 */
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF; /* 背景を完全に白に設定 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* 必要に応じてz-indexを調整 */
  opacity: 1; /* 初期状態は不透明 */
  transition: opacity 0.5s ease-out; /* 透明度の変化を0.5秒かけて行う */
}

.popup.hidden {
  opacity: 0; /* 完全に透明に */
  pointer-events: none; /* フェードアウト後はクリックを無効に */
}

.popup-image {
  max-width: 70%; /* PCのデフォルトでは画像の最大幅を70%に制限 */
  max-height: 70%; /* PCのデフォルトでは画像の最大高さを70%に制限 */
  object-fit: contain; /* 画像がコンテナに収まるように調整 */
  transition: transform 0.3s ease-out; /* 画像サイズの変更をスムーズに表示 */
}

/* スマホサイズでのスタイル調整 */
@media (max-width: 768px) {
  .popup-image {
    max-width: 120%; /* スマホでは画像の最大幅を120%に拡大 */
    max-height: 120%; /* スマホでは画像の最大高さを120%に拡大 */
  }
}

.sub-menu {
  padding-left: 20px; /* サブメニューの左パディング */
}

.menu-link {
  cursor: pointer;
  display: block; /* リンクをブロック表示に */
}

.menu-link.event-toggle {
  position: relative;
  cursor: pointer;
  padding-left: 25px; /* テキストと矢印の間に余白を設ける */
}

.menu-link.event-toggle::before {
  content: '▼'; /* 閉じているときは下向き矢印 */
  position: absolute;
  left: 0; /* テキストの左側に表示 */
  transition: transform 0.3s ease-in-out;
}

.menu-link.event-toggle.open::before {
  transform: rotate(180deg); /* 開いているときは上向き矢印 */
}
