  .modal {
    padding: 20px;
    background: #282c34; /* ヘッダーと同じ背景色を使用 */
    color: white; /* テキストの色を白に */
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal h2 {
    font-size: 24px;
    color: white; /* 見出しの色も白に */
  }
  
  .modal form {
    display: flex;
    flex-direction: column;
  }
  
  .modal input {
    padding: 10px;
    margin-top: 10px;
    border: 2px solid #fff; /* 白色の境界線 */
    background: transparent;
    color: white;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .modal button {
    padding: 10px;
    margin-top: 20px;
    background-color: #61dafb; /* ボタンの背景色をApp-linkと同じに */
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal button:hover {
    background-color: #0056b3; /* ホバー時の色変更 */
  }
  