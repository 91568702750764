.event-page {
    position: fixed; /* Fixed position to cover the whole viewport */
    top: 0; /* Start from the top of the viewport */
    left: 0; /* Start from the left of the viewport */
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    background: black; /* Set the background to black, adjustable as needed */
  }
  
  .full-screen-image {
    width: auto; /* Default: Width adjusts automatically */
    height: 100vh; /* Default: Full viewport height */
    object-fit: contain; /* Ensures the whole image is visible, may not cover the entire width */
  }
  
  /* スマホサイズでのスタイル調整 */
  @media (max-width: 768px) {
    .full-screen-image {
      width: 100vw; /* Full viewport width for mobile */
      height: auto; /* Adjust height automatically */
      object-fit: contain; /* Ensures the whole image is visible */
    }
  }
  